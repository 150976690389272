import { Link, useNavigate, useParams } from "react-router-dom";
import { graphql } from "../../gql";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { Inputs, SpotInfoRevisionSuggestionForm } from "../../components/SpotInfoRevisionSuggestionForm";

const mutationDocument = graphql(`
mutation SpotInfoRevisionSuggestionCreate($input: SpotInfoRevisionSuggestionCreateInput!) {
  spotInfoRevisionSuggestionCreate(input: $input) {
    spotInfoRevisionSuggestion {
      content
      createdAt
      id
      kind
      spot {
        name
      }
      updatedAt
    }
  }
}
`)

function InfoRevisionSuggestion() {
  const params = useParams()
  const [createReview, { data, loading, error }] = useMutation(mutationDocument)
  const navigate = useNavigate()

  const onSubmit = (input: Inputs) => {
    console.log(input)

    createReview({ variables: { input: { spotInfoRevisionSuggestionInput: { 
      spotCode: input.spotCode,
      content: input.content,
      kind: input.kind
    }}}})
  }

  useEffect(() => {
    if (!data || loading || error) return

    navigate(`/spots/${params.spotCode}`)
  }, [data, loading, error, navigate, params.spotCode])

  return (
    <div className={`w-full absolute left-0 top-10 h-full bg-white`}>
      <Link to={`/spots/${params.spotCode}`} className="m-3 mx-5">&lt;&lt; お店情報に戻る</Link>
      <SpotInfoRevisionSuggestionForm spotCode={params.spotCode || ""} onSubmit={onSubmit} loading={loading} />
    </div>
  );
}

export default InfoRevisionSuggestion