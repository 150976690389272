import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "flowbite-react"
import { useState } from "react"

function CrowdedAdvice({ text }: {text: string}) {
  const [summary, setSummary] = useState(true)
  const summaryText = (summary: boolean, text: string) => {
    if (summary) {
      return (
        <p className="text-center">{text.slice(0, 30)}...<button onClick={() => setSummary((sum) => !sum)} className="font-semibold underline">続きを見る</button></p>
      )
    } else {
      const textList: string[] = text ? text.split("\n") : []
      return textList.map((t, index) => {
          return (
            <p key={index} className="indent-3 whitespace-break-spaces text-center mb-4">{t}</p>
          )
        })
    }
  }
  return (
    <div className="bg-[#fed7aa]">
      <h2 className="flex gap-3 text-xs p-1 ml-3 font-bold">
        imako混雑アドバイス
        <Tooltip content="このお店の混雑時間やおすすめポイントを教えますよ">
          <span className='text-gray-400'>
            <FontAwesomeIcon icon={faCircleQuestion} />
          </span>
        </Tooltip>
      </h2>
      <div className="bg-[#ffedd5] p-3 text-xs">
        {summaryText(summary, text)}
      </div>
    </div>
  )
}

export default CrowdedAdvice
