import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import banner from '@image/banner.png'

export function Header({ onButtonClick }: { onButtonClick: () => void }){
  return (
    <header className="flex justify-between sticky bg-primary p-2 text-white">
      <button className="w-4" onClick={onButtonClick}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div className="">
        <img src={banner} alt="今ここ空いてる？を知りたい私の imako" />
      </div>
      <div className="w-4">

      </div>
    </header>
  )
}