type PropType = {
  phoneNumber: string
  addressfull: string
  businessHoursNote: string
  seatsText: string
  smoking: string
  homepageUrl?: string
  snsUrls?: string[]
}

export function DetailDetail({
  phoneNumber,
  addressfull,
  businessHoursNote,
  seatsText,
  smoking,
  homepageUrl,
  snsUrls,
}: PropType) {
  return (
    <dl className="flex flex-wrap w-full text-xs m-y-3">
      <dt className="w-3/12 border-b-2 p-2 text-caption">住所</dt>
      <dd className="w-9/12 border-b-2 p-2">{addressfull}</dd>
      <dt className="w-3/12 border-b-2 p-2 text-caption">電話番号</dt>
      <dd className="w-9/12 border-b-2 p-2">{phoneNumber}</dd>
      <dt className="w-3/12 border-b-2 p-2 text-caption">営業時間</dt>
      <dd className="w-9/12 border-b-2 p-2 whitespace-pre-line">{businessHoursNote ? businessHoursNote.replace("all:", "") : ""}</dd>
      {seatsText && (
        <>
          <dt className="w-3/12 border-b-2 p-2 text-caption">席数</dt>
          <dd className="w-9/12 border-b-2 p-2 whitespace-pre-line">{seatsText}</dd>
        </>
      )}
      <dt className="w-3/12 border-b-2 p-2 text-caption">喫煙/禁煙</dt>
      <dd className="w-9/12 border-b-2 p-2 whitespace-pre-line">{smoking}</dd>
      {homepageUrl && (
        <>
          <dt className="w-3/12 border-b-2 p-2 text-caption">HP</dt>
          <dd className="w-9/12 border-b-2 p-2"><a href={homepageUrl} target="_blank" rel="noopener noreferrer">{homepageUrl}</a></dd>
        </>
      )}
      {snsUrls && (
        <>
          <dt className="w-3/12 border-b-2 p-2 text-caption">SNS</dt>
          <dd className="w-9/12 border-b-2 p-2">{snsUrls}</dd>
        </>
      )}
    </dl>
  )
}
