import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import './utils/sentry'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes'
import { ApolloProvider } from '@apollo/client'
import { client as apolloClient } from '~/utils/apiClient'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </React.StrictMode>,
)
