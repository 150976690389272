import { SubmitHandler, useForm } from "react-hook-form"
import { Button, Datepicker, Label, Select, Textarea, CustomFlowbiteTheme } from 'flowbite-react'

type Props = React.PropsWithChildren<{
  spotCode: string,
  onSubmit: SubmitHandler<Inputs>,
  loading: boolean
}>

export type Inputs = {
  spotCode: string,
  comment?: string,
  crowdedLevel: string,
  visitedOn: Date,
  visitedTime: string 
}

export function SpotReviewForm({ spotCode, onSubmit }: Props) {
  const { register, handleSubmit, setValue, formState: { isValid } } = useForm<Inputs>()


  const wrapOnSubmit = (data: Inputs) => {
    onSubmit(data)
  }

  const handleSelectedDateChanged = (date: Date) => {
    setValue('visitedOn', date)
  }

  const datepickerTheme: CustomFlowbiteTheme["datepicker"] = {
    popup: {
      root: {
        base: "absolute top-10 z-50 block pt-2 -left-20"
      },
    },
  }

  return (
    <div className="text-center">
      <h1 className="text-xl font-bold p-5">混雑口コミを投稿する</h1>
      <h3 className="text-base font-bold py-2">＼混雑情報をお待ちしております／</h3>
      <p className="text-xs mx-5 py-1">imakoの混雑データは、imakoスタッフによる現地調査、 みなさんの口コミ情報をもとに、生成しています。</p>
      <p className="text-xs mx-5 py-1">より情報の精度を上げるために、ぜひ情報提案をお寄せください。</p>
      <p className="text-xs mx-5 py-1">(回答目安30秒)</p>

      <form className="text-left mt-5" onSubmit={handleSubmit(wrapOnSubmit)}>
        <input type="hidden" {...register("spotCode", { required: true }) } value={spotCode} />
        <div className="flex w-full md:w-10/12 m-auto px-5 py-3">
          <div className="text-nowrap text-xs min-w-20 mr-3 content-center">
            <Label htmlFor="visitedOn" value="提案日時" className="after:content-['*'] after:text-red-600 text-xs" />
          </div>
          <div className="w-30 mr-1">
            <Datepicker theme={datepickerTheme} maxDate={new Date()} onSelectedDateChanged={handleSelectedDateChanged} {...register("visitedOn", { required: true })} />
          </div>
          <div className="w-30">
            <Select {...register("visitedTime", { required: true })} id='colowded-level'>
              <option></option>
              <option value="1">開店〜12時</option>
              <option value="2">12時〜14時</option>
              <option value="3">14時〜16時</option>
              <option value="4">16時〜18時</option>
              <option value="5">18時〜22時</option>
              <option value="6">22時〜閉店</option>
             </Select>
          </div>
        </div>

        <div className="flex w-full md:w-10/12 m-auto px-5 py-3">
          <div className="text-nowrap text-xs min-w-20 mr-3 content-center">
            <Label htmlFor="crowdedLevel" value="混雑状況"  className="after:content-['*'] after:text-red-600 text-xs" />
          </div>
          <div className="w-60">
            <Select {...register("crowdedLevel", { required: true })} id='colowded-level'>
              <option></option>
              <option value='noCrowded'>入れた(0組待ち)</option>
              <option value='littleCrowded'>少し待った(1〜3組待ち)</option>
              <option value='crowded'>並んだ(4〜6組待ち)</option>
              <option value='veryCrowded'>かなり並んだ(7組以上)</option>
              <option value='unknown'>不明</option>
             </Select>
          </div>
        </div>

        <div className="w-full md:w-10/12 m-auto px-5 py-3">
          <div>
            <Label htmlFor="crowdedLevel" value="混雑状況コメント"  className="after:content-['(任意)'] text-xs" />
          </div>
          <div>
            <Textarea {...register("comment")} className="w-full md:w-10/12" rows={4} />
          </div>
        </div>

        <div className="w-full md:w-10/12 m-auto px-5 py-3">
          <div>
            <Button type="submit" className="bg-primary w-56 p-2 mx-auto" disabled={!isValid}>回答する</Button>
          </div>
        </div>
      </form>
    </div>
  )
}
