import { Cluster, ClusterStats, DefaultRenderer, Marker } from "@googlemaps/markerclusterer"
import { SpotClusterMarkerElement } from "./SpotClusterMarker"

export class ClusterMarkerRenderer extends DefaultRenderer {
  render(cluster: Cluster, stats: ClusterStats, map: google.maps.Map): Marker {
    if (!cluster.markers) return super.render(cluster, stats, map)
    if (cluster.marker) return cluster.marker

    const marker = cluster
      .markers[0] as google.maps.marker.AdvancedMarkerElement
    const content = marker.content as HTMLElement

    return SpotClusterMarkerElement(
      {
        position: cluster.position,
        name: marker.title,
        crowdedLevel: content.dataset?.crowdedLevel ?? 'noData',
        imageUrl: content.dataset?.imageUrl ?? '',
        showTitle: false,
        markerCount: cluster.markers.length,
      },
      document.createElement('div'),
      map,
    )
  }
}