import { Button } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { CrowdedLabelWithColor } from '../CrowdedIcon'
import { Pricing } from './Pricing'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiamondTurnRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { Latlng } from '~/models/PositionType.ts'
import { SpotFragment } from './DetailPanel'
import { FragmentType, useFragment } from '~/gql'

export type DetailInfoProps = React.PropsWithChildren<{
  spotFragment: FragmentType<typeof SpotFragment>
  position: Latlng | undefined
}>

export function DetailInfo({ spotFragment, position }: DetailInfoProps) {
  const spot = useFragment(SpotFragment, spotFragment)
  const [mapNaviUrl, setMapNaviUrl] = useState('')

  useEffect(() => {
    if (position && spot) {
      setMapNaviUrl(
        `https://www.google.com/maps/dir/?api=1&origin=${position.lat},${position.lng}&destination=${spot.latitude},${spot.longitude}&travelmode=walking`,
      )
    }
  }, [position, spot])

  return (
    <>
      <div className="float-right flex flex-wrap gap-2">
        <Button
          size="xs"
          pill
          href={`tel:${spot.phoneNumber}`}
          className="bg-[#179AAE]/[.1] font-bold content-center"
          disabled={!spot.phoneNumber}
        >
          <div className="text-[#179AAE]">
            <FontAwesomeIcon icon={faPhone} />
          </div>
        </Button>
        <Button
          size="xs"
          pill
          href={mapNaviUrl}
          className="bg-[#179AAE]/[.1] font-bold"
        >
          <div className="text-[#179AAE]">
            <FontAwesomeIcon icon={faDiamondTurnRight} />
          </div>
        </Button>
      </div>
      <div className="flex items-center">
        <div className="w-full text-xs">
          <Pricing
            kind="lunch"
            min={spot.budgetLunch?.min}
            max={spot.budgetLunch?.max}
          />
          <span className="mr-1" />
          <Pricing
            kind="dinner"
            min={spot.budgetDinner?.min}
            max={spot.budgetDinner?.max}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-full text-xs content-center">
          {spot.isOpen ? (
            <span className="text-primary mr-2 font-bold">現在営業中</span>
          ) : (
            <span className="text-[#FF6C6C] mr-2 font-bold">営業時間外</span>
          )}
          <span className="text-2xs">
            (
            {spot.todayBusinessHour.map((val, index) => (
              <span key={index}>
                {index == 0 ? '' : ','}
                {`${val.startTime} - ${val.endTime}`}
              </span>
            ))}
            )
          </span>
        </div>
      </div>
      {spot.crowdedLevel === 'unknown' || spot.crowdedLevel === 'noData' ? (
        ''
      ) : (
        <div className="flex items-center">
          <div className="font-bold text-xs text-nowrap mr-3">
            現在の混雑状況
          </div>
          <div className="min-w-auto">
            <CrowdedLabelWithColor crowdedType={spot.isOpen ? spot.crowdedLevel : 'noBusinessHours'} />
          </div>
        </div>
      )}
    </>
  )
}
