import styles from './CrowdedTable.module.scss'
import { CrowdedIcon, CrowdedLevelNotion } from '../CrowdedIcon/CrowdedIcon'
import { Table } from 'flowbite-react'

type CrowdedInformationType = {
  crowdedMatrix: {
    endTime: string
    startTime: string
    key: string
  }
  crowdLevel: string
}

export type CrowdedTableProps = {
  crowdedInformations: CrowdedInformationType[],
  lastCheckedOn?: Date
}

function CrowdedTable({ crowdedInformations, lastCheckedOn }: CrowdedTableProps) {
  const timeHeaders: string[] = [
    '〜12時',
    '〜14時',
    '〜16時',
    '〜18時',
    '〜22時',
    '〜閉店',
  ]

  const filterCrowdedInfo = (
    info: CrowdedInformationType[],
    weekdayType: string,
  ) => {
    return info
      .filter(
        (element) =>
          element.crowdedMatrix.key.indexOf(weekdayType) !== -1
      )
      .sort((a, b) => (a.crowdedMatrix.key.localeCompare(b.crowdedMatrix.key)))
      .map((obj) => ({ crowdedStyle: obj.crowdLevel }))
  }

  const crowdedInfo = [
    filterCrowdedInfo(crowdedInformations, 'weekday'),
    filterCrowdedInfo(crowdedInformations, 'holiday'),
  ]

  const currentDate = new Date()
  const currentIndex = (date: Date) => {
    const hour = date.getHours()
    if (hour < 5) {
      return 6
    } else if (hour < 12) {
      return 1
    } else if (hour < 14) {
      return 2
    } else if (hour < 16) {
      return 3
    } else if (hour < 18) {
      return 4
    } else if (hour < 22) {
      return 5
    } else {
      return 6
    }
  }

  return (
    <div className={`overflow-x-auto ${styles.crowdedtable}`}>
      { lastCheckedOn && (
          <div className="text-xs text-right">
            ※更新日時 {lastCheckedOn.toString()}
          </div>
      )}
      <Table className="border border-1">
        <Table.Head>
          <Table.HeadCell className="border text-2xs p-1 text-center max-w-4 text-wrap">時間</Table.HeadCell>
          {timeHeaders.map((timeHeader, index) => {
            return (
              <Table.HeadCell
                key={index}
                className={`p-0 text-center text-2xs border min-w-10 md:min-w-14 ${currentIndex(currentDate) === index + 1 ? 'bg-[#FF9797]/50' : ''}`}
              >
                {timeHeader}
              </Table.HeadCell>
            )
          })}
        </Table.Head>
        <Table.Body>
          {['平日', '休日'].map((day, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell className="border text-2xs p-1 text-center max-w-4 text-wrap">
                  {day}
                </Table.Cell>
                {crowdedInfo[index].map((crowded, index2) => {
                  return (
                    <Table.Cell key={index2} className={`p-0 text-center border min-w-10 md:min-w-14 ${currentIndex(currentDate) === index2 + 1 ? 'bg-[#FF9797]/50' : ''}`}>
                      <CrowdedIcon crowdedType={crowded.crowdedStyle} />
                    </Table.Cell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <div>
        <CrowdedLevelNotion />
      </div>
    </div>
  )
}

export default CrowdedTable
