import { SubmitHandler, useForm } from "react-hook-form"
import { Button, Label, Select, Textarea } from 'flowbite-react'

type Props = React.PropsWithChildren<{
  spotCode: string,
  onSubmit: SubmitHandler<Inputs>,
  loading: boolean
}>

export type Inputs = {
  spotCode: string,
  kind: string,
  content: string,
}

export function SpotInfoRevisionSuggestionForm({ spotCode, onSubmit }: Props) {
  const { register, handleSubmit, formState: { isValid } } = useForm<Inputs>()

  const wrapOnSubmit = (data: Inputs) => {
    onSubmit(data)
  }

  return (
    <div className="text-center">
      <h1 className="text-xl font-bold p-5">情報の修正を提案する</h1>
      <h3 className="text-base font-bold py-2">＼情報提案をお待ちしております／</h3>
      <p className="text-xs mx-5 py-1">imakoの混雑データは、imakoスタッフによる現地調査、 みなさんの口コミ情報をもとに、生成しています。</p>
      <p className="text-xs mx-5 py-1">より情報の精度を上げるために、ぜひ情報提案をお寄せください。</p>
      <p className="text-xs mx-5 py-1">(回答目安30秒)</p>

      <form className="text-left mt-5" onSubmit={handleSubmit(wrapOnSubmit)}>
        <input type="hidden" {...register("spotCode", { required: true }) } value={spotCode} />
        <div className="flex w-10/12 m-auto p-3">
          <div className="text-nowrap text-xs min-w-20 mr-3 content-center">
            <Label htmlFor="kind" value="情報の種類" className="after:content-['*'] after:text-red-600 text-xs" />
          </div>
          <div className="w-30">
            <Select {...register("kind", { required: true })} id='colowded-level'>
              <option></option>
              <option value="business_hour">営業時間・定休日</option>
              <option value="charge">料金</option>
              <option value="spot_name">店舗名</option>
              <option value="spot_closed">閉店情報</option>
              <option value="spot_phone">電話番号</option>
              <option value="spot_crowded_info">口コミ・混雑情報</option>
              <option value="other">その他</option>
             </Select>
          </div>
        </div>
        <div className="w-10/12 m-auto p-3">
          <div>
            <Label htmlFor="content" value="提案内容"  className="after:content-['*'] after:text-red-600 text-xs" />
          </div>
          <div>
            <Textarea {...register("content", { required: true })} className="w-full" rows={4} />
          </div>
        </div>

        <div className="w-10/12 m-auto p-3">
          <div>
            <Button type="submit" className="bg-primary w-56 p-2 mx-auto" disabled={!isValid}>回答する</Button>
          </div>
        </div>
      </form>
    </div>
  )
}