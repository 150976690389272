import {
  ApolloClient,
  createHttpLink,
  createQueryPreloader,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { firebaseAuth } from './firebase'

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URI ?? 'https://localhost:3001/graphql',
  fetchOptions: {
    mode: 'cors',
    method: 'POST',
  },
})

const authLink = setContext((_, { headers }) => {
  const currentUser = firebaseAuth.currentUser
  if (!currentUser) {
    return { headers }
  }

  return currentUser?.getIdToken(true).then((token: string) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export const preloadQuery = createQueryPreloader(client)
