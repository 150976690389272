import { Button, HR, Label, Modal, Radio, Select, TextInput } from 'flowbite-react'
import { useForm } from 'react-hook-form'

export type Inputs = {
  nickname: string,
  sex: string,
  ageGroup: string,
}

function FirstEnqueteForm({onSubmit}: {onSubmit: (data: Inputs) => void}) {
  const { register, handleSubmit, watch, formState: { isValid } } = useForm<Inputs>()

  return (
    <>
      <h1 className="text-center text-xl font-bold p-5">初回アンケート</h1>
      <div className="text-center">
      最適なご提案をさせていただきます。（目安30秒）
      </div>
      <HR />
      <form className="mt-10" onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="flex">
          <div className="text-nowrap text-xs min-w-20 mr-3 content-center">
            <Label
              htmlFor="nickname"
              value="ニックネーム"
              className="after:content-['*'] after:text-red-600 text-xs"
            />
          </div>
          <TextInput id="nickname" {...register("nickname", { required: true }) } required />
        </div>
        <HR />
        <div className="flex">
          <div className="text-nowrap text-xs min-w-20 mr-3 content-center">
            <Label
              htmlFor="sex"
              value="性別"
              className="after:content-['*'] after:text-red-600 text-xs"
            />
          </div>
          <div className="flex gap-3">
            <Radio className="hidden" id="woman" {...register("sex", { required: true }) } value="woman" />
            <Label htmlFor="woman" className={`rounded border p-2 ${watch('sex') == 'woman' ? 'bg-red-200': ''}`}>女性</Label>
            <Radio className="hidden" id="man" {...register("sex", { required: true }) }value="man" />
            <Label htmlFor="man" className={`rounded border p-2 ${watch('sex') == 'man' ? 'bg-red-200': ''}`}>男性</Label>
            <Radio className="hidden" id="not_answer" {...register("sex", { required: true }) } value="not_answer" />
            <Label htmlFor="not_answer" className={`rounded border p-2 ${watch('sex') == 'not_answer' ? 'bg-red-200': ''}`}>回答しない</Label>
          </div>
        </div>
        <HR />
        <div className="flex">
          <div className="text-nowrap text-xs min-w-20 mr-3 content-center">
            <Label
              htmlFor="age"
              value="年齢"
              className="after:content-['*'] after:text-red-600 text-xs"
            />
          </div>
          <Select id="ageGroup" {...register("ageGroup", { required: true })} required>
            <option value=""></option>
            <option value="before_nineteen">10歳以上</option>
            <option value="early_twenty">20〜24歳</option>
            <option value="late_twenty">25〜29歳</option>
            <option value="early_thirty">30〜34歳</option>
            <option value="late_thirty">35〜40歳</option>
            <option value="over_fourty">40歳以上</option>
          </Select>
        </div>
        <div className="text-center mt-10">
          <Button type="submit" className="w-full" disabled={!isValid}>回答する</Button>
        </div>
      </form>
    </>
  )
}

export function FirstEnquete({show = true, onSubmit}: {show?: boolean, onSubmit: (data: Inputs) => void}) {
  return (
    <>
      <Modal dismissible show={show} onClose={() => {}}>
        <Modal.Body>
          <FirstEnqueteForm onSubmit={onSubmit}/>
        </Modal.Body>
      </Modal>
    </>
  )
}
