import { CollisionBehavior, useMap } from '@vis.gl/react-google-maps'
import {
  type Marker,
  MarkerClusterer,
} from '@googlemaps/markerclusterer'
import { SpotsQueryQuery } from '../../gql/graphql'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SpotMarker } from './SpotMarker'
import { ClusterMarkerRenderer } from './ClusterMarkerRenderer'



export function ClusteredSpotMarkers({
  spots,
  showTitle,
  selectedCode,
  setSelectedCode,
  bbox,
}: {
  spots: SpotsQueryQuery['spots']
  showTitle: boolean
  selectedCode: string | undefined
  setSelectedCode: (code: string) => void
  bbox?: google.maps.LatLngBounds
}) {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({})
  const map = useMap()
  const clusterer = useMemo(() => {
    const algorithmOptions = { maxZoom: 21, radius: 100 }
    if (!map) return
    const renderer = new ClusterMarkerRenderer()
    return new MarkerClusterer({ map, renderer, algorithmOptions })
  }, [map])

  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key])) return markers
      if (marker) {
        return { ...markers, [key]: marker }
      } else {
        const { [key]: _, ...rest } = markers
        return rest
      }
    })
  }, [])

  useEffect(() => {
    if (!clusterer) return

    clusterer.clearMarkers()
    clusterer.addMarkers(Object.values(markers))
  }, [clusterer, markers])

  const containsBbox = (
    latlng: google.maps.LatLng | google.maps.LatLngLiteral,
  ) => {
    return bbox?.contains(latlng) ?? false
  }

  return (
    <>
      {spots.map((spotData, index) => {
        return (
          <SpotMarker
            key={index}
            spotData={spotData}
            collisionBehavior={CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL}
            showTitle={showTitle}
            selectedCode={selectedCode}
            setSelectedCode={setSelectedCode}
            setMarkerRef={setMarkerRef}
            containsBbox={containsBbox}
          />
        )
      })}
    </>
  )
}
