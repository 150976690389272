import { initializeApp, getApp, FirebaseApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth'
import { getAnalytics, logEvent } from 'firebase/analytics'

const APPNAME = 'imako-crowdmap'
let firebaseApp: FirebaseApp

try {
  firebaseApp = getApp(APPNAME)
} catch {
  firebaseApp = initializeApp(
    {
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_FIREBASE_APP_ID,
      measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
    },
    APPNAME,
  )
}

const firebaseAuth = getAuth(firebaseApp)

const firebaseAnalytics = getAnalytics(firebaseApp)

const waitUser = new Promise((resolve, _) => {
  let tryLogin = false
  const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
    if (user === null) {
      tryLogin = true
      signInAnonymously(firebaseAuth)
    } else {
      unsubscribe()
      if (tryLogin) logEvent(firebaseAnalytics, 'login', { method: 'Firebase' })
      resolve(user)
    } 
  })
})

export { firebaseAuth, firebaseApp, firebaseAnalytics, waitUser }
