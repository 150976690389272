import { faMinus, faUser, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type IconProps = React.PropsWithChildren<{
  crowdedType: string
}>

const getIcon = (crowdedType: string) => {
  switch (crowdedType) {
    case 'noCrowded':
      return <FontAwesomeIcon className="stroke-iconBorder stroke-xs" icon={faUser} />
    case 'littleCrowded':
    case 'crowded':
      return <FontAwesomeIcon className="stroke-iconBorder stroke-xs" icon={faUserGroup} />
    case 'veryCrowded':
      return <FontAwesomeIcon className="stroke-iconBorder stroke-xs" icon={faUsers} />
    case 'noBusinessHours':
      return <span className="text-sm text-[#FF6C6C]">終了</span>
    default:
      return <FontAwesomeIcon className="stroke-iconBorder stroke-xs" icon={faMinus} />
  }
}

const getColor = (crowdedType: string) => {
  switch (crowdedType) {
    case 'noCrowded':
      return 'text-notCrowded'
    case 'littleCrowded':
    case 'crowded':
      return 'text-littleCrowded'
    case 'veryCrowded':
      return 'text-veryCrowded'
    default:
      return 'text-black/50'
  }
}

const getLabel = (crowdedType: string) => {
  switch (crowdedType) {
    case 'noCrowded':
      return '入れるかも'
    case 'littleCrowded':
    case 'crowded':
      return '少し待つ'
    case 'veryCrowded':
      return '並ぶ'
    case 'noBusinessHours':
      return '終了'
    default:
      return '不明'
  }
}

export function CrowdedIcon({ crowdedType }: IconProps) {
  return (
    <>
      <span className={`text-lg ${getColor(crowdedType)}`}>
        {getIcon(crowdedType)}
      </span>
    </>
  )
}

export function CrowdedLabelWithColor({ crowdedType }: IconProps) {
  return (
    <>
      <span className={`${getColor(crowdedType)} text-[13px] font-bold text-nowrap`}>
        {getIcon(crowdedType)}
        { crowdedType === "noBusinessHours" ? "" : <span className="ml-1">{getLabel(crowdedType)}</span> }
      </span>
    </>
  )
}

export function CrowdedLevelNotion() {
  return (
    <div className="flex gap-1 text-xs">
      <div>
        <span className={getColor('noCrowded')}>{getIcon('noCrowded')}</span>...{getLabel('noCrowded')}
      </div>
      <div>
        <span className={getColor('littleCrowded')}>{getIcon('littleCrowded')}</span>...{getLabel('littleCrowded')}
      </div>
      <div>
        <span className={getColor('veryCrowded')}>{getIcon('veryCrowded')}</span>...{getLabel('veryCrowded')}
      </div>
      <div>
        [不明]...情報なし
      </div>
    </div>
  )
}