import {
  Map,
  useMap,
  MapControl,
  ControlPosition,
  MapCameraChangedEvent,
} from '@vis.gl/react-google-maps'
import { useCallback, useState } from 'react'
import { SpotsQueryQuery } from '~/gql/graphql'
import { useNavigate } from 'react-router-dom'
import { Button } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { GINZA_CENTER } from '~/utils/const'
import { Latlng } from '~/models/PositionType'
import { ClusteredSpotMarkers } from '../SpotMarker/ClusteredSpotMarkers'
import { CurrentPositionMarker } from '../CurrentPositionMarker'

type Props = {
  initialZoom?: number
  data: SpotsQueryQuery | undefined
  currentPosition: Latlng | undefined
  currentHeading: number
  reloadFunc: (cache?: boolean) => void
  onMoveCenter?: () => void
  onReloadCurrentTime?: () => void
}

function ImakoMap({
  initialZoom = 19,
  data,
  currentPosition,
  currentHeading,
  reloadFunc,
  onMoveCenter,
  onReloadCurrentTime 
}: Props): JSX.Element {
  const mapId = import.meta.env.VITE_GOOGLE_MAPS_ID
  const map = useMap()
  const [showTitle, setShowTitle] = useState(false)
  const navigate = useNavigate()
  const [selectedCode, setSelectedCode] = useState<string | undefined>()
  const [bbox, setBbox] = useState<google.maps.LatLngBounds>()
  const [_, setZoom] = useState<number>(initialZoom)

  const moveCenter = () => {
    if (!currentPosition) return
    map?.setCenter(currentPosition)
    if (onMoveCenter) onMoveCenter()
  }

  const handleCameraChanged = useCallback(
    (ev: MapCameraChangedEvent) => {
      setShowTitle(ev.detail.zoom > 18)
      setBbox(map?.getBounds())
      setZoom(ev.detail.zoom)
    },
    [setShowTitle, setBbox, setZoom, map],
  )

  const onReloadButtonClick = () => {
    reloadFunc(false)
    if (onReloadCurrentTime) onReloadCurrentTime()
  }

  const handleClick = () => {
    setSelectedCode(undefined)
    navigate('/')
  }

  return (
    <Map
      defaultCenter={GINZA_CENTER}
      defaultZoom={initialZoom}
      disableDefaultUI={true}
      minZoom={12}
      mapId={mapId}
      clickableIcons={false}
      gestureHandling="greedy"
      headingInteractionEnabled={true}
      onCameraChanged={handleCameraChanged}
      onClick={handleClick}
    >
      {currentPosition && (
        <CurrentPositionMarker currentPosition={currentPosition} currentHeading={currentHeading} />
      )}

      <MapControl position={ControlPosition.TOP_CENTER}>
        <Button
          color="light"
          className="xw-fit h-6 border-0 p-0 mt-5 rounded-full drop-shadow-xl flex items-center justify-center"
          onClick={onReloadButtonClick}
        >
          <span className="text-xs">現在時刻で再取得する</span>
        </Button>
      </MapControl>

      <MapControl position={ControlPosition.RIGHT_BOTTOM}>
        <Button
          color="light"
          className="ansolute w-10 h-10 bottom-40 right-5 rounded-full drop-shadow-xl"
          onClick={moveCenter}
        >
          <FontAwesomeIcon icon={faLocationCrosshairs} />
        </Button>
      </MapControl>

      {data?.spots ? (
        <ClusteredSpotMarkers
          spots={data.spots}
          showTitle={showTitle}
          selectedCode={selectedCode}
          setSelectedCode={setSelectedCode}
          bbox={bbox}
        />
      ) : null}
    </Map>
  )
}

export const MemoImakoMap = ImakoMap
