import {
  QueryRef,
  useLazyQuery,
  useMutation,
  useReadQuery,
} from '@apollo/client'
import { graphql } from '~/gql'
import { ToastContainer } from 'react-toastify'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { MapContainer } from '../components/MapContainer'
import { FirstEnquete } from '../components/FirstEnquete'
import { Outlet, useLoaderData } from 'react-router-dom'
import { Drawer } from 'flowbite-react'
import { useCallback, useEffect, useState } from 'react'
import { Header } from '../components/Header'
import { Sidebar } from '../components/Sidebar'
import { Latlng } from '../models/PositionType'
import { preloadQuery } from '../utils/apiClient'
import { firebaseAnalytics, waitUser } from '../utils/firebase'
import { Onboarding } from '../components/Onboarding'

import './root.scss'
import 'react-toastify/dist/ReactToastify.css'
import { MeQuery } from '../gql/graphql'
import { setUserId, logEvent } from 'firebase/analytics'

const spotsDocument = graphql(`
  query SpotsQuery($bbox: [Float!]) {
    spots(bbox: $bbox) {
      ...SpotsItem
    }
  }
`)

const userDocument = graphql(`
  query Me {
    me {
      code
      userAttribute {
        id
      }
      completeOnboarding
    }
  }
`)

const userAttributeMutationDocument = graphql(`
  mutation Mutation($input: EnqueteCreateInput!) {
    enqueteCreate(input: $input) {
      userAttribute {
        sex
        ageGroup
        nickname
      }
    }
  }
`)

const completeOnboardingMutationDocument = graphql(`
  mutation CompleteOnboarding(
    $CompleteOnboardingInput: CompleteOnboardingInput!
  ) {
    completeOnboarding(input: $CompleteOnboardingInput) {
      userOnboarding {
        id
        completedAt
      }
    }
  }
`)

// https://stackoverflow.com/questions/60640018/devicemotionevent-request-permission-with-typescript
interface DeviceOrientationEventiOS extends DeviceOrientationEvent {
  requestPermission?: () => Promise<'granted' | 'denied'>;
}

export async function loader() {
  await waitUser
  return preloadQuery(userDocument).toPromise()
}

function Root() {
  const loaderQueryRef = useLoaderData() as QueryRef<MeQuery, unknown>
  const { data: loadData } = useReadQuery(loaderQueryRef)
  const [getSpots, { loading, data }] = useLazyQuery(spotsDocument)
  const [createEnquete] = useMutation(userAttributeMutationDocument)
  const [completeOnboarding] = useMutation(completeOnboardingMutationDocument)
  const [showFirstEnquete, setShowFirstEnquete] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => setIsOpen(false)
  const [currentPosition, setCurrentPosition] = useState<Latlng | undefined>()
  const [currentHeading, setCurrentHeading] = useState<number>(0)
  const reloadFunc = useCallback(
    (cache = true) => {
      getSpots({
        variables: {},
        fetchPolicy: cache ? 'cache-first' : 'no-cache',
      })
    },
    [getSpots],
  )

  useRegisterSW({
    immediate: true,
  })

  const initGeo = () => {
    if ('geolocation' in navigator) {
      return navigator.geolocation.watchPosition(
        (position) => {
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
          // to GA
          logEvent(firebaseAnalytics, 'geolocation', {
            geolocationAvailable: true,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        },
        (error) => {
          console.error(error)
          logEvent(firebaseAnalytics, 'geolocation', {
            geolocationAvailable: false,
            error: error.message 
          })
        },
        { maximumAge: 0, timeout: 10000, enableHighAccuracy: false },
      )
    }
  }

  const removeGeo = (id: number) => {
    if (!id) return
    navigator.geolocation.clearWatch(id)
  }

  const initDeviceOrientation = async () => {
    if (DeviceOrientationEvent as unknown as DeviceOrientationEventiOS) {
      let status = "granted"
      const DevicerientationEventKlass = DeviceOrientationEvent as unknown as DeviceOrientationEventiOS
      if (typeof(DevicerientationEventKlass.requestPermission) === "function") {
        status = await DevicerientationEventKlass.requestPermission()
        logEvent(firebaseAnalytics, 'deviceorientationpermission', {
          status: status
        })
      }
      if (status === "granted") {
        window.addEventListener('deviceorientation', handleDeviceOrientation)
      } 
    }
  }
  const removeDeviceOrientation = () => {
    if (window.DeviceOrientationEvent) {
      window.removeEventListener('deviceorientation', handleDeviceOrientation)
    }
  }
  const handleDeviceOrientation = (event: DeviceOrientationEvent) => {
    setCurrentHeading(event.alpha ?? 0)
  }

  useEffect(() => {
    reloadFunc()
  }, [reloadFunc])

  // アンケートは表示しない
  // useEffect(() => {
  //   setShowFirstEnquete(!loadData.me?.userAttribute)
  // }, [loadData])

  useEffect(() => {
    if (!loadData.me?.code) return

    setUserId(firebaseAnalytics, loadData.me?.code)
    return () => {
      setUserId(firebaseAnalytics, null)
    }
  }, [loadData])

  const isCompleteOnboarding = loadData.me?.completeOnboarding

  useEffect(() => {
    if (!isCompleteOnboarding) return
    const watchId = initGeo()
    initDeviceOrientation()
    return () => {
      removeDeviceOrientation()
      if (watchId) removeGeo(watchId)
    }
  }, [isCompleteOnboarding])

  const handleOnboardingStart = () => {
    completeOnboarding({ variables: { CompleteOnboardingInput: {} } })
    initGeo()
    initDeviceOrientation()
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        theme="light"
        hideProgressBar
      />
      <Header
        onButtonClick={() => {
          setIsOpen((s) => !s)
        }}
      />
      <MapContainer
        loading={loading}
        data={data}
        reloadFunc={reloadFunc}
        currentPosition={currentPosition}
        currentHeading={currentHeading}
        onMoveCenter={() => logEvent(firebaseAnalytics, 'move_center', { })}
        onReloadCurrentTime={() => logEvent(firebaseAnalytics, 'reload_current_time', { })}
      />
      {showFirstEnquete && (
        <FirstEnquete
          onSubmit={(data) => {
            createEnquete({ variables: { input: { inputs: data } } })
            setShowFirstEnquete(false)
          }}
        />
      )}
      {!showFirstEnquete && !loadData.me?.completeOnboarding && (
        <Onboarding onStart={handleOnboardingStart} />
      )}
      <Outlet context={{ position: currentPosition }} />
      <Drawer open={isOpen} onClose={handleClose}>
        <Sidebar onButtonClick={() => setIsOpen(false)} />
      </Drawer>
    </>
  )
}

export default Root
