import ReactDOM from 'react-dom/client'
import { SpotHolder, SpotHolderLabel } from '../SpotHolder'

type Props = React.PropsWithChildren<{
  name: string
  crowdedLevel: string
  imageUrl: string
  showTitle: boolean
  markerCount: number
}>

function SpotClusterMarker({
  name,
  crowdedLevel,
  imageUrl,
  showTitle,
  markerCount,
}: Props) {
  return (
    <>
      <SpotHolder
        crowdedLevel={crowdedLevel}
        imageUrl={imageUrl}
        highlight={false}
        markerCount={markerCount}
      />
      {showTitle && <SpotHolderLabel text={name} highlight={false} />}
    </>
  )
}

type ElementOpts = {
  position: google.maps.LatLng
}

export function SpotClusterMarkerElement(
  props: Props & ElementOpts,
  container: Element,
  map: google.maps.Map,
) {
  container.innerHTML = ''
  const root = ReactDOM.createRoot(container)
  root.render(<SpotClusterMarker {...props} />)
  const options = {
    map,
    content: container,
    position: props.position,
    title: `${props.name} 他${props.markerCount}件`,
  }
  const marker = new google.maps.marker.AdvancedMarkerElement(options)
  return marker
}
