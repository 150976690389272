import { Link } from "react-router-dom"

export type Props = React.PropsWithChildren<{
  spotCode: string
}>

export function SpotReview({spotCode}: Props) {
  return (
    <div className="my-5">
      <h3 className="text-sm font-bold text-center">＼混雑情報のクチコミを募集中🙋‍️／</h3>
      <div className="text-center m-3">
        <Link to={`/spots/${spotCode}/review`}>
          <div className="bg-primary/50 text-wrap text-black rounded-lg p-1 shadow-lg w-max-60">
            <div className="font-bold text-base text-sm">混雑クチコミ投稿する</div>
            <div className="text-xs">(ログイン不要)</div>
          </div>
        </Link>
      </div>
    </div>
  )
}