type Props = { kind: string, min?: number | null, max?: number | null }

export function Pricing({kind, min, max}: Props) {
    return (
        <>
            <span className="mr-1">{kind === 'lunch' ? '昼' : '夜'}</span>
            { (min || max) ? <span>¥</span> : '' }
            { (!min && !max) ? "不明" : [(min ? <span key="1">{min.toLocaleString()}</span> : ''), (min ? <span key='2'>〜</span> : ''), (max ? <span key='3'>{max.toLocaleString()}</span> : '')] }
        </>
    )
}