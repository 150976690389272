import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CrowdedAdvice } from '../CrowdedAdvice'
import { CrowdedTable } from '../CrowdedTable'
import { DetailInfo } from './DetailInfo'
import {
  faArrowUpRightFromSquare,
  faMinus,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons'
import { SpotReview } from '../SpotReview'
import { CrowdedReview } from '../CrowdedReview'
import { Button } from 'flowbite-react'
import { toast } from 'react-toastify'
import { FragmentType, graphql, useFragment } from '../../gql'
import { Latlng } from '~/models/PositionType'
import { Link } from 'react-router-dom'
import { DetailDetail } from './DetailDetail'
import { SpotPhoto } from './SpotPhoto'

export const SpotFragment = graphql(`
  fragment SpotItem on Spot {
    budgetDinner {
      max
      min
    }
    budgetLunch {
      max
      min
    }
    code
    latitude
    longitude
    name
    phoneNumber
    homepageUrl
    isOpen
    imakoAdvice
    crowdedLevel
    addressfull
    businessHoursNote
    smoking
    seatsText
    todayBusinessHour {
      endTime
      startTime
    }
    spotCrowdInformationStatuses {
      lastCheckedOn
    }
    spotCrowdInformations {
      crowdedMatrix {
        endTime
        startTime
        key
      }
      crowdLevel
    }
    spotPhotos {
      imageUrl
      originalFrom
      originalUrl
      isImage
    }
  }
`)

type DetailPanelProp = {
  spotFragment: FragmentType<typeof SpotFragment>
  position: Latlng
  onSubmit: (data: { crowdedLevel: string }) => void
  onShare?: () => void
}

export function DetailPanel({
  spotFragment,
  position,
  onSubmit,
  onShare
}: DetailPanelProp) {
  const spot = useFragment(SpotFragment, spotFragment)
  const shareSpot = () => {
    const shareParam = {
      text: 'imako をシェアしよう',
      url: window.location.href,
    }
    if (navigator.canShare && navigator.canShare(shareParam)) {
      const shareFunc = async () => {
        await navigator.share(shareParam)
      }
      shareFunc()
    } else if (navigator.clipboard) {
      const clipFunc = async () => {
        await navigator.clipboard.writeText(window.location.href)
        toast('URLをコピーしました')
      }
      clipFunc()
    }
    if (onShare) onShare()
  }

  return (
    <div className="p-0.5 md:p-2 overflow-auto">
      <div className="pt-3">
        <div className="text-center text-gray-400 md:hidden">
          <FontAwesomeIcon icon={faMinus} />
        </div>
        <div className="flex">
          <h1 className="flex-1 text-xl font-bold">{spot.name}</h1>
          <div className="text-center rounded-full text-nowrap p-2 pr-1">
            <Button
              color="gray"
              className="border-0 bg-white w-fit w-10 text-black"
              onClick={() => shareSpot()}
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Button>
          </div>
        </div>
      </div>
      <div>
        <DetailInfo position={position} spotFragment={spotFragment} />
        {spot.spotPhotos.length < 1 ? (
          ''
        ) : (
          <SpotPhoto spotPhotos={spot.spotPhotos} />
        )}
        {spot.spotCrowdInformations.length < 1 ? (
          ''
        ) : (
          <div className="clear-right my-5">
            <CrowdedTable
              crowdedInformations={spot.spotCrowdInformations}
              lastCheckedOn={spot.spotCrowdInformationStatuses?.lastCheckedOn}
            />
          </div>
        )}
        <div className="mx-auto my-5">
          <CrowdedReview onSubmit={onSubmit} />
        </div>
        {spot.imakoAdvice === '' ? (
          ''
        ) : (
          <div className="mx-auto">
            <CrowdedAdvice text={spot.imakoAdvice} />
          </div>
        )}
        <div className="mx-auto">
          <SpotReview spotCode={spot.code} />
        </div>
        <div className="mx-auto">
          <DetailDetail
            phoneNumber={spot.phoneNumber || ''}
            addressfull={spot.addressfull || ''}
            businessHoursNote={spot.businessHoursNote || ''}
            seatsText={spot.seatsText}
            smoking={spot.smoking}
            homepageUrl={spot.homepageUrl || ''}
          />
        </div>
        <div className="mx-auto my-5">
          <div className="text-center">
            <Link to={`/spots/${spot.code}/suggestion`}>
              <div className="text-sm">
                店舗情報の修正を提案する
                <FontAwesomeIcon icon={faPenToSquare} />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
