import styles from "./SpotHolderLabel.module.scss"

export function SpotHolderLabel({ text, highlight }: { text: string, highlight: boolean }) {
  return (
    <div className={`
      ${styles.spotHolderLabel}
      text-center
      text-xs
      font-semibold
      absolute
      ${highlight ? "text-red-800" : "text-black"}
      `}>
      {text.length > 6 && !highlight ? text.slice(0, 6) + '...' : text}
    </div>
  )
}
