import { AdvancedMarker } from '@vis.gl/react-google-maps'
import { Latlng } from '../../models/PositionType'

export function CurrentPositionMarker({
  currentPosition,
  currentHeading,
}: {
  currentPosition: Latlng
  currentHeading: number
}) {
  return (
    <AdvancedMarker
      position={currentPosition}
      style={{ transform: `rotate(${-currentHeading}deg)` }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="23"
        viewBox="0 0 21 23"
        fill="none"
      >
        <circle cx="10" cy="17" r="6" fill="#179AAE" />
        <path
          d="M10.1306 21.4482L1.35734 5.36226L18.9038 5.36226L10.1306 21.4482Z"
          fill="url(#paint0_linear_2693_3122)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2693_3122"
            x1="10.1306"
            y1="0.000272751"
            x2="10.1306"
            y2="21.4482"
            gradientUnits="uSpaceOnUse"
          >
            <stop offset="0.435" stopColor="#179AAE" stopOpacity="0.35" />
            <stop offset="1" stopColor="#179AAE" />
          </linearGradient>
        </defs>
      </svg>
    </AdvancedMarker>
  )
}
